import axios from "axios";
import io from "socket.io-client";
// prod
// console.log(window.location.href.split("?")[1]);

export const baseUrl = "https://prod.api.degpeg.com"; // prod
// const contentProvider = "62036e02a958e382ff246d6a"; //prod
// const contentProvider = "6208cd418b73b7caf776d067";
// export const baseUrl = "https://staging.api.degpeg.com"; // dev
// export const baseUrl = "https://prod.api.degpeg.com"; // dev
// const contentProvider = "61f27a9f62269e59746a0114"; //dev

// console.log(window.location.href.split("?")[1].split("=")[1].split("&")[0]);
// const contentProvider = `${window.location.href.split("?")[1]}`;
const contentProvider =
  window.location.href.split("?")[1] &&
  window.location.href.split("?")[1].split("=")[1]
    ? `${window.location.href.split("?")[1].split("=")[1].split("&")[0]}`
    : "";

//shopify

// export const baseUrl = process.env.REACT_APP_API_BASE_URL | "https://prod.api.degpeg.com";// prod
// const contentProvider = process.env.REACT_APP_CONTENT_PROVIDER_ID | "61b02c36e85debebd7f79977"; //prod

// axios({
//   method: "POST",
//   url: `${baseUrl}/users/auth/token`,
//   headers: { "Content-Type": "application/json" },
//   data: JSON.stringify({
//     appId: "degpegRaghavendra_I6WR4mCF",
//     secretKey: "54J9nDL4nIfidjY3",
//   }),
// }).then((response) => {
//   localStorage.setItem("token", response.data.token);
//   return response.data.token;
// });

export async function getAllSessionsForcontentProvider() {
  const response = await axios
    .get(
      baseUrl + "/content-providers/" + contentProvider + "/live-sessions/",
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((res) => {
      // console.log(res.data);
      return res.data;
    });

  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/content-providers/${contentProvider}/live-sessions`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response.data);
  // return await response;
}

export async function getAllLiveSessionsProductCategory() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/live-session-categories/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.data);
  return await response;
}

export async function getAllSessionData() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/session-data`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response.data);
  return await response;
}

//chat-messages
export async function getAllChatMessages() {
  const response = await fetch(baseUrl + "/chat-messages/", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  });
  return await response.json();
}

export async function getSesseionChatMessages(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/chat-messages?filter={"where":{"liveSessionId":"${id}"}}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);

  return response;
}

export async function postSessionChatMessages(msg) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/chat-messages/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: JSON.stringify(msg),
  }).then((response) => response);

  return response;
}

export async function getUserDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"contentProviderId":"${contentProvider}"}}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  }).then((response) => response);
  return response;
}
export async function getBlockList(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/block-users?filter={"where":{"blockedby":"${id}"}}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    }
  }).then((response) => response);

  return response;
}
//Shopify-call
export async function getShopifyProductsBySessionId(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/live-sessions/${id}/ecommerce-products`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => {
    return response;
  });
  return await response;
}
export async function getShopifyProductsByFilter(shopName,filter) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/merchant-apps/products/${shopName}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: filter,
  }).then((response) => response);
  return await response;
}
export async function getShopDetails(id) {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users/${id}/merchants`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return await response;
}
export async function getProductsByShopName(shopName) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/merchant-apps/products/${shopName}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return await response;
}

//Video-detail & interaction
export async function getAllProdcts(id) {
  const response = await axios
    .get(
      baseUrl +
        "/content-providers/" +
        contentProvider +
        '/live-sessions?filter={"where":{"id":"' +
        id +
        '"}}',
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/content-providers/${contentProvider}/live-sessions?filter={"where":{"id":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

//getting products
export async function getProdcts(productId) {
  const response = await axios
    .get(baseUrl + '/products?filter={"where":{"id":"' + productId + '"}}', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/products?filter={"where":{"id":"'${productId}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

//getting ctas
export async function getCtas(ctaId) {
  const response = await axios
    .get(baseUrl + '/user-ctas?filter={"where":{"id":"' + ctaId + '"}}', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/user-ctas?filter={"where":{"id":"'${ctaId}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

// likes & shares & views

export async function getAllLikesViewsShares(id) {
  const response = await axios
    .get(
      `${baseUrl}/session-data?filter={"where":{"liveSessionId":"` + id + `"}}`,
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/session-data?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}

export async function postLikes(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/likes`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getLikes_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/likes/count?filter={"where":{"liveSessionId":"` + id + `"}}`,
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/likes?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
export async function postViews(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/views`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getViews_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/views/count?filter={"where":{"liveSessionId":"` + id + `"}}`,
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/views/count?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
export async function postShares(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/shares`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getShares_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/shares/count?filter={"where":{"liveSessionId":"` + id + `"}}`
    )
    .then((response) => response);
  return await response;
}
export async function postPurchases(data) {
  const response = axios({
    method: "POST",
    url: `${baseUrl}/purchases`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
    data: data,
  }).then((response) => response);
  return await response;
}
export async function getPurchases_Count(id) {
  const response = await axios
    .get(
      `${baseUrl}/purchases/count?filter={"where":{"liveSessionId":"` +
        id +
        `"}}`,
      // {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => response);
  return await response;
  // const response = axios({
  //   method: "GET",
  //   url: `${baseUrl}/purchases/count?filter={"where":{"liveSessionId":"'${id}'"}}`,
  //   headers: {
  //     Authorization: `Bearer ${localStorage.getItem("token")}`,
  //     "Content-Type": "application/json",
  //   },
  // }).then((response) => response);
  // return await response;
}
//contentprovider Details
export async function getContentProviderDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"contentProviderId":"${contentProvider}"}}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}
export async function getInfluencerDetails() {
  const response = axios({
    method: "GET",
    url: `${baseUrl}/users?filter={"where":{"influencerId":"606d3f8ce30ef9d7bbaf7e2c"}}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  }).then((response) => response);
  return response;
}

//socketConnection
export function socketConnection() {
  // const response = io(`https://dev-interaction.degpeg.com/${contentProvider}`);
  // const response = io(
  //   `https://prod.interactionserver.degpeg.com:9012/${contentProvider}`,
  //   { transports: ["polling"] }
  // );
  // const response = io.connect(`http://localhost:9010/${contentProvider}`);
  // const response = io(
  //   `https://dev.interactionserver.degpeg.com:9014/${contentProvider}`
  // );
  const response = io.connect(
    // Local Server
    // `http://localhost:9010/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    // dev Server
    // `https://dev.interactionserver.degpeg.com:9014/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    // testing
    // `https://test.interactionserver.degpeg.com:9015/content-provider`,
    // {
    //   query: {
    //     contentProviderId: `${contentProvider}`,
    //   },
    //   transports: ["polling"],
    // }
    //prod Server
    `https://prod.interactionserver.degpeg.com:9012/content-provider`,
    {
      query: {
        contentProviderId: `${contentProvider}`,
      },
      transports: ["polling"],
    }
  );

  return response;
}
