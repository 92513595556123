import React, { Component } from "react";
import videojs from "video.js";
import date from "date-and-time";
import {
  FacebookShareButton,
  // EmailShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  // EmailIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from "react-share";

import {
  getAllLikesViewsShares,
  postLikes,
  postViews,
  postShares,
  getLikes_Count,
  getViews_Count,
  getContentProviderDetails,
  getShopDetails,
} from "../API/index";
import muteIcon from "../../assets/img/muteIcon.svg";
import chatIcon from "../../assets/img/chat.svg";
import fullscreen from "../../assets/img/fullscreen.svg";
import exitscreen from "../../assets/img/exitscreen.svg";
import unmuteIcon from "../../assets/img/unmuteIcon.svg";
// import eyeIcon from "../../assets/img/eye.svg";
import thumbnildefault from "../../assets/img/default_thumbnail.png";
import likeIcon from "../../assets/img/like.png";

export default class VideoPlayer extends Component {
  // chat settings
  constructor(props) {
    super(props);
    this.state = {
      // data: "",
      shopName: "",
      imageUrl: "",
      ctaId: "",
      post: [],
      cta: [],
      product: [],
      productId: "",
      showModal: false,
      playVideo: false,
      videoMute: true,
      fullScreen: false,
      views_count: 26,
      posts: [],
      matches: window.matchMedia("(min-width: 768px)").matche,
      userName:
        localStorage.getItem("userName") === null
          ? ""
          : localStorage.getItem("userName"),
      userId: localStorage.getItem("userName"),
    };
  }

  componentDidMount() {
    // alert("Yes");
    // this.setState({ data: this.props.data });
    // console.log(this.props);
    function muteTag() {
      const mutediv = document.createElement("div");
      mutediv.innerText = "Video is mute";
      document.body.appendChild(mutediv);
      mutediv.setAttribute("id", "muteTag");
      // console.log(mutediv);
    }
    if (this.state.videoMute) {
      muteTag();
      setTimeout(() => {
        document.getElementById("muteTag").remove();
      }, 2000);
    }
    window.onload = () => {
      this.setState({
        posts: [],
      });
    };
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 768px)").addEventListener("change", handler);
    // console.log(this.state.userId);
    this.viewCount();
    const videoJsOptions = {
      posterImage: true,
      autoplay: true,
      muted: true,
      controls: true,
      responsive: true,
      liveui: true,
      liveTracker: true,
      controlBar: {
        fullscreenToggle: false,
        seekToLive: true,
      },
      userActions: {
        doubleClick: false,
        exitscreen: false,
        fullscreen: false,
      },
      sources: [
        {
          src: this.props.videoUrl,
          type: this.props.status === "live" ? "application/x-mpegURL" : "",
        },
      ],
    };
    this.player = videojs(
      this.videoNode,
      { ...videoJsOptions },
      function onPlayerReady() {
        // alert(this.paused());

        this.setState({ playVideo: !this.paused() });
        // this.setState({ videoMute: this.muted });
        // console.log("onPlayerReady", this);
      }
    );
    getContentProviderDetails().then((userDeatails) => {
      getShopDetails(userDeatails.data[0].id).then((shop) => {
        // console.log(shop);
        // if (userDeatails.data.length > 0) {
        if (shop.data.length > 0) {
          this.setState({ shopName: shop.data[0].shopName });
        }
        // }
      });
    });
  }
  // componentDidUpdate(prevProps) {
  //   console.log(prevProps);
  //   if (prevProps.data.id !== this.state.data.id) {
  //     this.setState({ data: this.props.data });
  //     console.log("componentDidUpdate method is called", this.props.data);
  //   }
  // }
  // destroy player on unmount
  componentWillUnmount() {
    // const { socket } = this.state;
    if (this.player) {
      this.player.dispose();
    }
  }

  shareListener = (share) => {
    console.log("");
  };

  shareView(data) {
    this.setState({
      posts: [],
    });
    getAllLikesViewsShares(this.props.session_id).then((response) => {
      this.setState({ posts: response.data });
      response.data.map((postData) => {
        const now = new Date();
        const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
        var msg = {
          liveSessionId: this.props.session_id,
          time_stamp: dt,
          userId: "",
          source: "web",
        };
        // console.log(msg);
        postShares(msg).then((res) => {});
        return 0;
      });
    });
  }

  toggleShare = () => {
    if (!this.state.isShare) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState((prevState) => ({
      isShare: !prevState.isShare,
    }));
  };
  toggleMute = () => {
    if (!this.state.videoMute) {
      this.setState({ videoMute: true });
      this.player.muted(true);
    } else {
      this.setState({ videoMute: false });
      this.player.muted(false);
    }
  };
  toggleFullScreen = () => {
    window.landscapeMode();
    this.props.toggleFullScreen();
  };
  handleOutsideClick = (e) => {
    if (!this.node.contains(e.target)) this.toggleShare();
  };
  likeCount = () => {
    window.heart();
    this.setState({
      posts: [],
    });
    getAllLikesViewsShares(this.props.session_id).then((response) => {
      this.setState({ posts: response.data });
      response.data.map((postData) => {
        const now = new Date();
        const dt = date.format(now, "YYYY-MM-DDTHH:mm:ss.msZ");
        var msg = {
          time_stamp: dt,
          liveSessionId: this.props.session_id,
          userId: "",
          source: "web",
        };
        postLikes(msg).then((res) => {
          getLikes_Count(this.props.session_id).then((res) => {
            // console.log("like_ount", res);
          });
        });
        return 0;
      });
    });
  };
  viewCount = () => {
    this.setState({
      posts: [],
    });

    getAllLikesViewsShares(this.props.session_id).then((response) => {
      this.setState({ posts: response.data });
      response.data.map((postData) => {
        const now = new Date();
        const dt = new Date(
          now.getTime() - now.getTimezoneOffset() * 60000
        ).toISOString();
        var msg = {
          liveSessionId: this.props.session_id,
          start: dt,
          end: dt,
          source: "web",
        };
        postViews(msg).then((res) => {
          getViews_Count(this.props.session_id).then((res) => {
            // console.log("views_count", res);
            this.setState({ views_count: res.data.count });
          });
        });
        return 0;
      });
    });
  };
  // chat toggle
  toggleChat = () => {
    this.props.toggleChat();
  };
  formatViews = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(2) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };
  render() {
    const { isShare } = this.state;
    // console.log('result mode',this.props);
    return (
      <>
        <div
          className={`videoplayer__wrapper ${
            this.props.videoMode &&
            this.props.videoMode.toUpperCase() === "PORTRAIT"
              ? "portrait-mode"
              : ""
          }`}
        >
          <video-js
            data-setup='{ "inactivityTimeout": 0 }'
            data-vjs-player
            className="videojs-wrap"
          >
            <video
              ref={(node) => (this.videoNode = node)}
              className="video-js"
              webkitplaysinline="true"
              autoPlay={true}
              preload="auto"
              muted={true}
              playsInline={true}
              poster={
                this.props.status === "scheduled"
                  ? thumbnildefault
                  : this.props.poster
              }
            ></video>
            <div className="videoplayer__controllbar vjs-control-bar">
              <button className="btn-mute-unmute" onClick={this.toggleMute}>
                <img
                  src={this.state.videoMute ? unmuteIcon : muteIcon}
                  alt="mute"
                />
              </button>
              <button className="m-hide progressbar"></button>
              <button className="m-show btn-chat" onClick={this.toggleChat}>
                <img src={chatIcon} alt="chat" />
                {/* <span>10</span> */}
              </button>
              {/* {(this.props.cta.length > 0 || this.props.product.length > 0) && ( */}
              <button
                className="m-show btn-cart"
                onClick={() => this.props.toggleCta()}
              >
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path
                    d="M26.7098 27.3026L24.6177 7.81071C24.5828 7.49689 24.3039 7.2528 23.99 7.2528H20.1195V6.06725C20.1195 2.7198 17.3997 0 14.0523 0C10.7049 0 7.98505 2.7198 7.98505 6.06725V7.2528H4.00996C3.69614 7.2528 3.41718 7.49689 3.38231 7.81071L1.29016 27.3026C1.25529 27.477 1.32503 27.6513 1.42964 27.7908C1.53424 27.9303 1.70859 28 1.88294 28H26.1171C26.2914 28 26.4657 27.9303 26.5704 27.7908C26.675 27.6513 26.7447 27.477 26.7098 27.3026ZM19.4919 10.7746C19.8057 10.7746 20.0498 11.0187 20.0498 11.3325C20.0498 11.6463 19.8057 11.8904 19.4919 11.8904C19.1781 11.8904 18.934 11.6463 18.934 11.3325C18.934 11.0187 19.1781 10.7746 19.4919 10.7746ZM9.24035 6.06725C9.24035 3.41719 11.4022 1.25529 14.0523 1.25529C16.7024 1.25529 18.8643 3.41719 18.8643 6.06725V7.2528H9.24035V6.06725ZM8.6127 10.7746C8.92652 10.7746 9.17061 11.0187 9.17061 11.3325C9.17061 11.6463 8.92652 11.8904 8.6127 11.8904C8.29888 11.8904 8.05479 11.6463 8.05479 11.3325C8.05479 11.0187 8.29888 10.7746 8.6127 10.7746ZM2.61519 26.7098L4.56787 8.47322H7.98505V9.62391C7.28767 9.86799 6.7995 10.5305 6.7995 11.2976C6.7995 12.274 7.60149 13.076 8.57783 13.076C9.55417 13.076 10.3562 12.274 10.3562 11.2976C10.3562 10.5305 9.86799 9.86799 9.17061 9.62391V8.47322H18.7945V9.62391C18.0971 9.86799 17.609 10.5305 17.609 11.2976C17.609 12.274 18.411 13.076 19.3873 13.076C20.3636 13.076 21.1656 12.274 21.1656 11.2976C21.1656 10.5305 20.6775 9.86799 19.9801 9.62391V8.47322H23.5367L25.4894 26.7098H2.61519Z"
                    fill="white"
                  />
                </svg>
              </button>
              {/* )} */}
              <button className="video-like" onClick={this.likeCount}>
                <div id="hearContainer">
                  <img
                    id="bigHeart"
                    src={likeIcon}
                    alt="heart"
                    height="90%"
                    width="90%"
                  />
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path
                      d="M20.0608 0.000650766C18.907 -0.00681048 17.7661 0.292281 16.7187 0.876735C15.6713 1.46119 14.7432 2.3167 14 3.38266C13.2617 2.3098 12.3345 1.44946 11.286 0.86438C10.2375 0.279296 9.09415 -0.015785 7.93924 0.000650766C5.83362 0.000650766 3.81425 1.01097 2.32535 2.80935C0.836453 4.60773 0 7.04686 0 9.59015C0 18.6659 13.1139 27.5277 13.6456 27.8701C13.7505 27.9548 13.8738 28 14 28C14.1262 28 14.2495 27.9548 14.3544 27.8701C14.8861 27.5277 28 18.7944 28 9.59015C28 7.04686 27.1635 4.60773 25.6747 2.80935C24.1858 1.01097 22.1664 0.000650766 20.0608 0.000650766ZM14 26.1577C11.9443 24.7022 1.41772 16.9535 1.41772 9.59015C1.41772 7.50102 2.10481 5.49745 3.32783 4.02021C4.55085 2.54297 6.20963 1.71306 7.93924 1.71306C9.02411 1.69586 10.0947 2.01314 11.0498 2.63489C12.0049 3.25663 12.8129 4.16232 13.3975 5.26631C13.4665 5.38127 13.5571 5.47484 13.6618 5.53937C13.7665 5.60389 13.8824 5.63753 14 5.63753C14.1176 5.63753 14.2335 5.60389 14.3382 5.53937C14.4429 5.47484 14.5335 5.38127 14.6025 5.26631C15.3717 3.84539 16.5005 2.76267 17.8205 2.17946C19.1405 1.59626 20.5812 1.54378 21.9279 2.02985C23.2746 2.51592 24.4553 3.51453 25.294 4.87688C26.1327 6.23924 26.5846 7.89243 26.5823 9.59015C26.5823 17.0391 16.0557 24.745 14 26.1577Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </button>
              <button
                ref={(node) => {
                  this.node = node;
                }}
                onClick={this.toggleShare}
                className="btn-share"
              >
                <svg width="20" height="30" viewBox="0 0 20 30" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.6667 30H3.33333C1.492 30 0 28.5404 0 26.7391V15C0 13.1993 1.492 11.7391 3.33333 11.7391H6C6.36867 11.7391 6.66667 12.0313 6.66667 12.3913C6.66667 12.7513 6.36867 13.0435 6 13.0435H3.33333C2.22933 13.0435 1.33333 13.92 1.33333 15V26.7391C1.33333 27.8198 2.22933 28.6957 3.33333 28.6957H16.6667C17.7713 28.6957 18.6667 27.8198 18.6667 26.7391V15C18.6667 13.92 17.7713 13.0435 16.6667 13.0435H14C13.6313 13.0435 13.3333 12.7513 13.3333 12.3913C13.3333 12.0313 13.6313 11.7391 14 11.7391H16.6667C18.508 11.7391 20 13.1993 20 15V26.7391C20 28.5404 18.508 30 16.6667 30ZM14.8893 6.33587L10.6667 2.205V18.913C10.6667 19.2737 10.3687 19.5652 10 19.5652C9.63133 19.5652 9.33333 19.2737 9.33333 18.913V2.205L5.11067 6.33587C4.85667 6.58435 4.44467 6.58435 4.19 6.33587C3.936 6.08739 3.936 5.6837 4.19 5.43522L9.46 0.279783C9.48133 0.24913 9.49533 0.213913 9.52333 0.18587C9.65333 0.0593478 9.824 -0.00130436 9.99333 0.00130433C9.996 0.00130433 9.99733 0 10 0C10.0027 0 10.0047 0.00130433 10.0067 0.00130433C10.1773 -0.000652191 10.3473 0.0593478 10.4767 0.18587C10.504 0.213261 10.5167 0.247174 10.5373 0.277174L15.81 5.43522C16.064 5.6837 16.064 6.08739 15.81 6.33587C15.5553 6.58435 15.1433 6.58435 14.8893 6.33587Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                className="btn-expand"
                onClick={this.props.launchFullScreen}
              >
                <img
                  src={this.props.fullScreen ? exitscreen : fullscreen}
                  alt="screen"
                />
              </button>
              <div
                className={`btn-share-content icons-share ${
                  isShare ? "isactive" : ""
                }`}
              >
                <FacebookShareButton
                  url={
                    window.location.href.indexOf("brand") > -1
                      ? window.location.href
                          .split("&brand=")[1]
                          .includes("https://")
                        ? window.location.href.split("&brand=")[1]
                        : "https://" +
                          window.location.href.split("&brand=")[1] +
                          "?&id=" +
                          this.props.session_id
                      : window.location.href.split("?")[0] +
                        "?id=" +
                        this.props.contentProviderId +
                        "&session=" +
                        this.props.session_id
                  }
                  quote={"CHECK OUT THIS"}
                  hashtag={"#LiveTv"}
                  onClick={() => this.shareView("Facebook")}
                >
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
                <TwitterShareButton
                  url={
                    window.location.href.indexOf("brand") > -1
                      ? window.location.href
                          .split("&brand=")[1]
                          .includes("https://")
                        ? window.location.href.split("&brand=")[1]
                        : "https://" +
                          window.location.href.split("&brand=")[1] +
                          "?&id=" +
                          this.props.session_id
                      : window.location.href.split("?")[0] +
                        "?id=" +
                        this.props.contentProviderId +
                        "&session=" +
                        this.props.session_id
                  }
                  onClick={() => this.shareView("Twitter")}
                >
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={
                    window.location.href.indexOf("brand") > -1
                      ? window.location.href
                          .split("&brand=")[1]
                          .includes("https://")
                        ? window.location.href.split("&brand=")[1]
                        : "https://" +
                          window.location.href.split("&brand=")[1] +
                          "?&id=" +
                          this.props.session_id
                      : window.location.href.split("?")[0] +
                        "?id=" +
                        this.props.contentProviderId +
                        "&session=" +
                        this.props.session_id
                  }
                  separator=" :: "
                  onClick={() => this.shareView("Whatsapp")}
                >
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <LinkedinShareButton
                  url={
                    window.location.href.indexOf("brand") > -1
                      ? window.location.href
                          .split("&brand=")[1]
                          .includes("https://")
                        ? window.location.href.split("&brand=")[1]
                        : "https://" +
                          window.location.href.split("&brand=")[1] +
                          "?&id=" +
                          this.props.session_id
                      : window.location.href.split("?")[0] +
                        "?id=" +
                        this.props.contentProviderId +
                        "&session=" +
                        this.props.session_id
                  }
                  onClick={() => this.shareView("Linkedin")}
                >
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </div>
            </div>
            {this.props.videoMode &&
            this.props.videoMode.toUpperCase() === "PORTRAIT" ? (
              ""
            ) : (
              <>{this.props.children}</>
            )}
            <div className="live-views">
              <span
                className={`livetags ${
                  this.props.status === "live" ? "live" : ""
                }`}
              >
                {this.props.status}
              </span>
              {/* <h6>
                <img src={eyeIcon} alt="eye" />
                {this.formatViews(this.state.views_count)} Views
              </h6> */}
            </div>
          </video-js>
        </div>
      </>
    );
  }
}
