import React, { Component } from "react";
import {
  getAllProdcts,
  getCtas,
  getProdcts,
  getPurchases_Count,
  postPurchases,
  getShopifyProductsBySessionId,
  getContentProviderDetails,
  getShopDetails,
  getShopifyProductsByFilter,
} from "../API";

import downArrow from "../../assets/img/down-arrow.svg";
import cartIcon from "../../assets/img/cartimg.svg";

export default class Cta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      cta: [],
      minimize: false,
      shopifyProductIds: [],
      shopName: "",
      shopifyAllProducts: [],
      shopifyTest: [],
      shopifyDataList: "",
      productFilter: { limit: 250 },
    };
  }
  componentDidMount() {
    if (this.props.session_id) {
      getAllProdcts(this.props.session_id).then((response) => {
        response.data.length > 0 &&
          response.data[0].products &&
          response.data[0].products.map((item) => {
            getProdcts(item).then((data) => {
              this.setState({
                product: [...this.state.product, data.data[0]],
              });
            });
            return 0;
          });
        response.data.length > 0 &&
          response.data[0].ctaIds &&
          response.data[0].ctaIds.map((item) => {
            getCtas(item).then((data) => {
              this.setState({
                cta: [...this.state.cta, data.data[0]],
              });
            });
            return 0;
          });
      });
      getShopifyProductsBySessionId(this.props.session_id).then((response) => {
        //sessionId

        response.data.forEach((element) => {
          this.setState({
            shopifyProductIds: [
              ...this.state.shopifyProductIds,
              element.productId,
            ],
          });
          this.setState({
            shopifyDataList:
              this.state.shopifyDataList && this.state.shopifyDataList.length
                ? this.state.shopifyDataList + "," + element.productId
                : element.productId,
          });
        });
        this.state.productFilter["ids"] = [
          this.state.shopifyDataList.toString(),
        ];
        getContentProviderDetails().then((userDeatails) => {
          //contentprovider
          getShopDetails(userDeatails.data[0].id).then((shop) => {
            if (shop.data.length > 0) {
              this.setState({ shopName: shop.data[0].shopName });
              getShopifyProductsByFilter(
                shop.data[0].shopName,
                this.state.productFilter
              ).then((filterres) => {
                if (filterres.data.body.products && filterres.data.body.products.length > 0) {
                  filterres.data.body.products.forEach((item) => {
                  this.setState({
                    shopifyTest: [...this.state.shopifyTest, item],
                  });
                });
                }
              });
            }
          });
        });
      });
    }
  }
  purchaseUpdateForProduct = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "1",
    };
    postPurchases(msg).then((res) => {
      getPurchases_Count(this.props.session_id).then((res) => console.log(""));
    });
  };
  purchaseUpdateForCta = () => {
    const now = new Date();
    const dt = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000
    ).toISOString();
    var msg = {
      liveSessionId: this.props.session_id,
      time_stamp: dt,
      amount: "0",
    };
    postPurchases(msg).then((res) => {
      getPurchases_Count(this.props.session_id).then((res) => console.log(""));
    });
  };
  minimizebtn = () => {
    this.props.toggleMinimize();
  };
  render() {
    // console.log("product", this.state.shopifyProductIds);
    // console.log("data list", this.state.shopifyDataList);
    return (
      <>
        <div
          className={`cta__wrapper 
        ${
          window.innerWidth > 991
            ? this.props.videoMode &&
              this.props.videoMode.toUpperCase() === "PORTRAIT"
              ? ""
              : this.state.product.length > 0 ||
                this.state.shopifyTest.length > 0 ||
                this.state.cta.length > 0
              ? ""
              : "nocta"
            : ""
        }
        ${this.props.minimize ? "cta__close" : ""} ${
            this.props.videoMode &&
            this.props.videoMode.toUpperCase() === "PORTRAIT"
              ? "portrait-mode"
              : ""
          } ${
            this.props.landScape === 1 ? "portraitActive" : "portraitInactive"
          }`}
        >
          <div className="m-btn-minimize m-show" onClick={this.minimizebtn}>
            <img src={downArrow} alt="down-arrow" />
          </div>
          <div className="cta__heading">
            <h4>Products</h4>
          </div>
          {/* desktop show */}
          {this.state.shopName ||
          this.state.product.length > 0 ||
          this.state.shopifyTest.length > 0 ||
          this.state.cta.length > 0 ? (
            <>
              <div
                className={`cta__detailwrap portrait-mode ${
                  this.props.landScape === 1 ? "active" : "inactive"
                }`}
              >
                {/*Shopify product fetching card */}

                <div className="cta__cardwrap">
                  {this.state.shopifyTest.map((product, index) => (
                    <div className="cta__wrap" key={index}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img src={ product.image?.src != null ? product.image.src : ""} alt="img" />
                        </div>
                        <div className="cta__desc">
                          <h5>{product.title}</h5>
                          <div className="cta__btngroup">
                            <h4>{product.variants[0].price}</h4>
                            <a
                              href={
                                "https://" +
                                this.state.shopName +
                                "/products/" +
                                product.handle
                              }
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForProduct}
                            >
                              Buy Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* product fetching card */}
                  {this.state.product.map((products) => (
                    <div className="cta__wrap" key={products.id}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img src={products.image_url} alt="img" />
                        </div>
                        <div className="cta__desc">
                          <h5>{products.name}</h5>
                          <div className="cta__btngroup">
                            <h4>
                              {products.currency} {products.price}
                            </h4>
                            {products.purchase_link.includes("https://") ? (
                              <a
                                href={products.purchase_link}
                                target="_blank"
                                className="btn btn__primary"
                                rel="noreferrer"
                                onClick={this.purchaseUpdateForProduct}
                              >
                                <img src={cartIcon} alt="cart" />
                                Buy Now
                              </a>
                            ) : (
                              <a
                                href={"https://" + products.purchase_link}
                                target="_blank"
                                className="btn btn__primary"
                                rel="noreferrer"
                                onClick={this.purchaseUpdateForProduct}
                              >
                                <img src={cartIcon} alt="cart" />
                                Buy Now
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* product fetching card */}
                  {this.state.cta.map((ctas) => (
                    <div className="cta__wrap" key={ctas.id}>
                      <div className="cta__card">
                        <div className="cta__img">
                          <img src={ctas.filepath} alt="img" />
                        </div>
                        <div className="cta__desc">
                          <h4>{ctas.name}</h4>
                          {ctas.ctaLink.includes("https://") ? (
                            <a
                              href={ctas.ctaLink}
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForCta}
                            >
                              Click Here
                            </a>
                          ) : (
                            <a
                              href={"https://" + ctas.ctaLink}
                              target="_blank"
                              className="btn btn__primary"
                              rel="noreferrer"
                              onClick={this.purchaseUpdateForCta}
                            >
                              Click Here
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="cta__nocta">
              <p>No Product Available</p>
            </div>
          )}
        </div>
        <div className="cta__mobileshow" onClick={() => this.props.toggleCta()}>
          <div className="cta__cardwrap">
            {this.state.shopifyTest.slice(0, 1).map((product, index) => (
              <div className="cta__wrap" key={index}>
                <div className="cta__card">
                  <div className="cta__img">
                    <img src={ product.image?.src != null ? product.image.src : ""} alt="img" />
                  </div>
                </div>
              </div>
            ))}
            {/* product fetching card */}
            {this.state.product.slice(0, 1).map((products) => (
              <div className="cta__wrap" key={products.id}>
                <div className="cta__card">
                  <div className="cta__img">
                    <img src={products.image_url} alt="img" />
                  </div>
                </div>
              </div>
            ))}
            {/* product fetching card */}
            {this.state.cta.slice(0, 1).map((ctas) => (
              <div className="cta__wrap" key={ctas.id}>
                <div className="cta__card">
                  <div className="cta__img">
                    <img src={ctas.filepath} alt="img" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}
