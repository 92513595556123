import React, { useState, useEffect } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  getAllSessionsForcontentProvider,
  getCtas,
  getProdcts,
  getContentProviderDetails,
  getAllProdcts,
} from "../../components/API";
import Slider from "react-slick";
import Chat from "../../components/Chat";
import Cta from "../../components/Cta";
import date from "date-and-time";
import VideoPlayerHeader from "../../components/VideoHeader";
import VideoPlayer from "../../components/VideoPlayer";
import comingsoonBanner from "../../assets/img/default_thumbnail.png";
import plannedImg from "../../assets/img/planned.svg";
// import errorpage from "../../assets/img/errorpage.jpg";
import PlayCard from "../../components/PlayCard/PlayCard";
import PlayCardHeading from "../../components/PlayCard/PlayCardHeading";
import EventCardCategory from "../../components/EventCard/EvenetCard";
import { ReactComponent as ArrowDowneIcon } from "../../assets/img/arrowbottom.svg";
import liveBanner from "../../assets/img/stream.gif";
import pastVideoEmpty from "../../assets/img/pastvideos.png";
import eventsEmpty from "../../assets/img/events.png";
// import liveend from "../../assets/img/live-end.gif";

const Dashboard = () => {
  const [fullScreen, setFullScreen] = useState(false);
  const [landScape, setLandScape] = useState(2);
  const [minimize, setMinimize] = useState(true);
  const [data, setData] = useState(null);
  const [product, setProduct] = useState([]);
  const [cta, setCta] = useState([]);
  const [allVedios, setAllvedios] = useState([]);
  const [distanceTime, setDistance] = useState("");
  const [id, setId] = useState(null);
  const [upcomingVideos, setUpcomingVideos] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(null);
  const [temporyLiveBanner, setTemporyLiveBanner] = useState(false);
  const [pastVideos, setPastVideos] = useState([]);
  const [liveThumbnail, setLiveThumbnail] = useState(false);
  const numberOfItems = itemsToShow ? upcomingVideos.length : 4;
  const settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5.4,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true,
          // arrows: false,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1.4,
          slidesToScroll: 1,
          draggable: true,
          swipeToSlide: true,
          touchMove: true,
          // arrows: false,
        },
      },
    ],
  };
  // toggle chat
  const toggleChat = () => {
    setMinimize(!minimize);
    if (landScape === 1) {
      setLandScape(2);
      setMinimize(false);
    }
  };
  // toggle cta
  const toggleCta = () => {
    setMinimize(!minimize);
    if (landScape === 2) {
      setLandScape(1);
      setMinimize(false);
    }
  };
  // toggle minize
  const toggleMinimize = () => {
    if (!minimize) {
      setMinimize(true);
    } else {
      setMinimize(false);
    }
  };
  useEffect(() => {
    if (window.location.href.split("?")[1]) {
      // console.log(window.location.href.split("?"));
      getAllSessionsForcontentProvider().then((res) => {
        res = res.filter((item) => item.status !== "deleted");

        // res = res.sort(function (o1, o2) {
        //   return o1.date_time ? -1 : o2.date_time ? 1 : 0;
        // });
        res = res.sort(function (o1, o2) {
          return new Date(o1.date_time).getTime() - new Date().getTime() >
            new Date(o2.date_time).getTime() - new Date().getTime()
            ? -1
            : new Date(o1.date_time).getTime() - new Date().getTime() <
              new Date(o2.date_time).getTime() - new Date().getTime()
            ? 1
            : 0;
        });

        getContentProviderDetails().then((providerData) => {
          if (providerData.data.length > 0) {
            setAllvedios(res);
            // console.log(res);
            if (
              window.location.href.split("&")[1] &&
              window.location.href.split("&")[1].includes("session")
            ) {
              setId(window.location.href.split("&")[1].split("=")[1]);
            }
            if (window.location.href.split("&brand=")[1]) {
            }

            const ss = res.filter(
              (item) => item.status === "planned" || item.status === "scheduled"
            );
            let timerData = [];
            const notimerData = [];
            ss.map((item) => {
              let diff =
                new Date(item.date_time).getTime() - new Date().getTime();

              if (diff > 0) {
                timerData.push(item);
              } else {
                notimerData.push(item);
              }
              return 0;
            });

            timerData.sort(function (o1, o2) {
              return new Date(o1.date_time).getTime() - new Date().getTime() <
                new Date(o2.date_time).getTime() - new Date().getTime()
                ? -1
                : new Date(o1.date_time).getTime() - new Date().getTime() >
                  new Date(o2.date_time).getTime() - new Date().getTime()
                ? 1
                : 0;
            });
            setUpcomingVideos([...timerData, ...notimerData]);
            const liveVideo = [];
            const completedVideo = [];
            const plannedVideo = [];
            const plannedVideos = [...timerData, ...notimerData];
            res.map((item) => {
              if (item.status !== "deleted") {
                if (item.status === "scheduled" || item.status === "planned") {
                  plannedVideo.push(item);
                } else if (item.status === "live") {
                  liveVideo.push(item);
                } else {
                  if (item.web_video_url && item.status === "completed") {
                    completedVideo.push(item);
                  }
                }
              }
              return 0;
            });

            setPastVideos(completedVideo);
            if (id === null) {
              if (liveVideo.length > 0) {
                setData(liveVideo[0]);
                liveVideo[0].products &&
                  liveVideo[0].products.map((item) => {
                    getProdcts(item).then((data) => {
                      setProduct([...product, data.data[0]]);
                    });
                    return 0;
                  });
                liveVideo[0].ctaIds &&
                  liveVideo[0].ctaIds.map((item) => {
                    getCtas(item).then((data) => {
                      setCta([...cta, data.data[0]]);
                    });
                    return 0;
                  });
              } else if (completedVideo.length > 0) {
                setData(completedVideo[0]);
                completedVideo[0].products &&
                  completedVideo[0].products.map((item) => {
                    getProdcts(item).then((data) => {
                      // console.log(data);
                      setProduct([...product, data.data[0]]);
                    });
                    return 0;
                  });
                completedVideo[0].ctaIds &&
                  completedVideo[0].ctaIds.map((item) => {
                    getCtas(item).then((data) => {
                      setCta([...cta, data.data[0]]);
                    });
                    return 0;
                  });
              } else if (plannedVideos.length > 0) {
                setData(plannedVideos[0]);
                timer(plannedVideos[0]);
                plannedVideos[0].products &&
                  plannedVideos[0].products.map((item) => {
                    getProdcts(item).then((data) => {
                      // console.log(data);
                      setProduct([...product, data.data[0]]);
                    });
                    return 0;
                  });
                plannedVideos[0].ctaIds &&
                  plannedVideos[0].ctaIds.map((item) => {
                    getCtas(item).then((data) => {
                      setCta([...cta, data.data[0]]);
                    });
                    return 0;
                  });
              } else setData("coming");
            } else {
              const s = allVedios.filter((item) => item.id === id);
              setData(s[0]);
              setProduct([]);
              setCta([]);
              s[0].products
                ? s[0].products.map((item) => {
                    getProdcts(item).then((data) => {
                      setProduct([...product, data.data[0]]);
                    });
                    return 0;
                  })
                : setProduct([]);
              s[0].ctaIds
                ? s[0].ctaIds.map((item) => {
                    getCtas(item).then((data) => {
                      setCta([...cta, data.data[0]]);
                    });
                    return 0;
                  })
                : setCta([]);
            }
          } else {
            setData(null);
          }
        });
      });
    } else {
      setData(null);
    }
    // eslint-disable-next-line
  }, [setData, id, setId, setCta, setProduct, setAllvedios]);

  const timer = (data) => {
    var countDownDate = new Date(data.date_time).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setDistance(days + "d " + hours + "h " + minutes + "m " + seconds + "s ");
      if (distance < 0) {
        clearInterval(x);
        setDistance("");
      } else if (distance === 0) {
        clearInterval(x);
        setData(data);
      }
    }, 1000);
  };
  const launchFullScreen = () => {
    if (!window.isiPhone) {
      if (window.isInFullScreen()) {
        window.exitFullScreen();
        setFullScreen(false);
      } else {
        window.requestFullScreen(
          document.getElementById("fullscreen") || document.body
        );
        setFullScreen(true);
      }
    }

    document.onfullscreenchange = function () {
      const element = document.fullscreenElement;
      if (element) {
        setFullScreen(true);
      } else {
        setFullScreen(false);
      }
    };
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const temp = document.getElementsByClassName("video-js");
      if (temp[0] && data && data.status === "live") {
        if (temp[0].classList.contains("vjs-live") === true) {
          setTemporyLiveBanner(false);
        } else {
          setTemporyLiveBanner(true);
        }
      }

      if (data && data.status === "live") {
        getAllProdcts(data.id).then((res) => {
          if (res.data[0].status === "completed") {
            setTimeout(() => {
              setLiveThumbnail(true);
            }, 10000);
          }
        });
      }
    }, 1000);
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  });

  return data !== null && allVedios.length > 0 ? (
    <div>
      <div
        className={`${
          !fullScreen
            ? "template__container"
            : "template__container fullscreen-mode"
        }`}
      >
        {allVedios.map((item) => {
          if (item.id === data.id) {
            return (
              <div className="template__sdk" key={item.id}>
                {data.status !== "scheduled" &&
                data.status !== "planned" &&
                !temporyLiveBanner ? (
                  !liveThumbnail ? (
                    <div
                      className={`${
                        !fullScreen
                          ? "template__wrapper"
                          : "template__wrapper fullscreen-video"
                      } ${
                        data.videoMode &&
                        data.videoMode.toUpperCase() === "PORTRAIT"
                          ? "portrait-mode"
                          : ""
                      }`}
                      id="fullscreen"
                    >
                      {data.videoMode &&
                      data.videoMode.toUpperCase() === "PORTRAIT" ? (
                        <Chat
                          session_id={data.id}
                          videoMode={data.videoMode}
                          status={data.status}
                          landScape={landScape}
                          minimize={minimize}
                          toggleMinimize={toggleMinimize}
                          ischatenabled={data.isChatEnabled}
                        />
                      ) : (
                        ""
                      )}
                      <VideoPlayer
                        poster={data.web_banner_url}
                        data={data}
                        session_id={data.id}
                        dateTime={data.date_time}
                        status={data.status}
                        videoUrl={data.web_video_url}
                        launchFullScreen={launchFullScreen}
                        fullScreen={fullScreen}
                        toggleChat={toggleChat}
                        toggleCta={toggleCta}
                        cta={cta}
                        videoMode={data.videoMode}
                        product={product}
                        contentProviderId={data.contentProviderId}
                      >
                        {data.status !== "scheduled" && (
                          <Chat
                            session_id={data.id}
                            videoMode={data.videoMode}
                            status={data.status}
                            landScape={landScape}
                            minimize={minimize}
                            toggleMinimize={toggleMinimize}
                            ischatenabled={data.isChatEnabled}
                          />
                        )}
                      </VideoPlayer>
                      <Cta
                        session_id={data.id}
                        videoMode={data.videoMode}
                        landScape={landScape}
                        minimize={minimize}
                        toggleMinimize={toggleMinimize}
                        status={data.status}
                        toggleCta={toggleCta}
                      />
                    </div>
                  ) : (
                    <div className="template__wrapper">
                      <div className="videoplayer__wrapper ended-session">
                        <img
                          src={data.web_banner_url}
                          alt=""
                          className="poster"
                        />
                        <div className="ended-test">
                          <span>
                            Stay tuned for the next live stream because this one
                            is over <br />
                            <b>This live stream ends here</b>
                          </span>
                        </div>
                      </div>
                      {product.length > 0 || cta.length > 0 ? (
                        <Cta
                          session_id={data.id}
                          landScape={landScape}
                          minimize={minimize}
                          toggleMinimize={toggleMinimize}
                          status={data.status}
                          toggleCta={toggleCta}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  )
                ) : data.status !== "live" ? (
                  <div className="planned_timer_wrapper">
                    <div className="planned_timer">
                      Starting In
                      <br />
                      {distanceTime}
                    </div>
                    <img src={plannedImg} alt="planned" />
                  </div>
                ) : (
                  temporyLiveBanner && (
                    <div>
                      <img src={liveBanner} alt="live" />
                    </div>
                  )
                )}
              </div>
            );
          } else {
            return "";
          }
        })}
        {data.status !== "scheduled" &&
        data.status !== "planned" &&
        data.status !== "live" &&
        data.status !== "completed" ? (
          <img src={comingsoonBanner} alt="coming soon" />
        ) : (
          ""
        )}
        <VideoPlayerHeader
          dateTime={data.date_time}
          status={data.status}
          name={data.name}
          description={data.description}
        />
      </div>

      {/* past */}
      <section className="common__section relatedvideos">
        <div className="container">
          <PlayCardHeading title="Past" titleHighlight="Videos" />
        </div>

        {pastVideos.length > 0 ? (
          <div className="common__slider">
            <Slider {...settings}>
              {allVedios.map((data, index) =>
                data.web_video_url && data.status === "completed" ? (
                  <PlayCard
                    key={index}
                    dateTime={data.date_time}
                    status={data.status}
                    videoid={data.id}
                    titleImg={
                      data.web_banner_url
                        ? data.web_banner_url
                        : data.banner_url
                    }
                    titleVideo={data.web_video_url}
                    titleName={data.name}
                    titleDesc={data.description}
                    setData={setData}
                    setId={setId}
                    item={data}
                    id={id}
                  />
                ) : (
                  ""
                )
              )}
            </Slider>
          </div>
        ) : (
          <img src={pastVideoEmpty} alt="past" className="emptyStatus" />
        )}
      </section>
      {/* up coming events */}
      <section className="common__section">
        <div className="container">
          <PlayCardHeading title="Upcoming" titleHighlight="Events" />
          {upcomingVideos.length > 0 ? (
            <div className="common__wrapper d-flex">
              {upcomingVideos.slice(0, numberOfItems).map((data, index) => {
                return (
                  <EventCardCategory
                    key={index}
                    eventImg={data.web_banner_url}
                    title={data.name}
                    desc={data.description}
                    calender={date.format(
                      new Date(data.date_time),
                      "ddd, MMM DD YYYY"
                    )}
                    time={date.format(new Date(data.date_time), "hh:mm A")}
                    data={data}
                    // distance={distanceTime}
                  />
                );
              })}
            </div>
          ) : (
            <img src={eventsEmpty} alt="events" className="emptyStatus" />
          )}
        </div>
        {upcomingVideos.length > 4 && (
          <div
            className={`showaction ${itemsToShow ? "showless" : "showmore"}`}
          >
            <span onClick={() => setItemsToShow(!itemsToShow)}>
              {itemsToShow ? "Show less" : " Show more"}
              <ArrowDowneIcon />
            </span>
          </div>
        )}
      </section>
    </div>
  ) : (
    <>
      <div>
        <img src={comingsoonBanner} alt="error" />
      </div>
    </>
  );
};

export default Dashboard;
